import 'normalize.css'
import '@/assets/scss/index.scss'

import Vue from 'vue'
import App from './App.vue'
import {ApiService} from './services/api.service'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import {VueKeycloakOptions} from '@dsb-norge/vue-keycloak-js/dist/types'

const api = new ApiService(process.env.VUE_APP_API_BASE_URL)

Vue.config.productionTip = false
Vue.prototype.$api = api

Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'login-required'
  },
  config: {
    url: process.env.VUE_APP_KC_URL,
    realm: 'customers',
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
  },
  onReady: async (kc) => {
    await api.setToken(kc.token)

    new Vue({
      render: h => h(App)
    }).$mount('#app')
  },
  onAuthRefreshSuccess (kc) {
    api.setToken(kc.token)
  }
} as VueKeycloakOptions)
